<template>
  <div class="col-span-12">
    <!-- BEGIN: Change Password -->
    <div class="intro-y box mt-1">
      <div class="flex justify-start p-3 border-b border-gray-200 dark:border-dark-5">
        <h2 class="font-medium text-base ml-5 mt-1 mr-10">Dòng tiền cập nhật (Live)</h2>
        <Litepicker
          v-model="dateRange"
          :options="{
            singleMode: false,
            numberOfColumns: 2,
            numberOfMonths: 2,
            showWeekNumbers: true,
            dropdowns: {
              minYear: 1990,
              maxYear: null,
              months: true,
              years: true,
            },
          }"
          class="input border w-56 block mr-3"
        />
        <button type="button" class="button bg-theme-1 flex mr-auto justify-center text-white" @click="searchTraffic">
          <LoadingIcon color="white" class="w-4 h-4 mr-1" v-if="loading" />
          <SearchIcon class="w-4 h-4 mr-1" v-else /> Tra Cứu
        </button>
      </div>
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-5 p-5">
          <table class="table table-auto border-separate border">
            <tr>
              <td></td>
              <td>Tiền COD</td>
              <td>Vận phí</td>
              <td>Số đơn</td>
            </tr>
            <tr>
              <td>COD đã thu</td>
              <td>{{ $h.formatCurrency(codsPayed.cod) }}</td>
              <td>{{ $h.formatCurrency(codsPayed.freight) }}</td>
              <td>{{ $h.formatCurrency(codsPayed.totalnumber) }}</td>
            </tr>
            <tr>
              <td>COD chờ thanh toán</td>
              <td>{{ $h.formatCurrency(codsWait.cod) }}</td>
              <td>{{ $h.formatCurrency(codsWait.freight) }}</td>
              <td>{{ $h.formatCurrency(codsWait.totalnumber) }}</td>
            </tr>
          </table>
        </div>
        <div class="col-span-7">
          <div class="p-5 flex-col justify-start">
            <h3>
              <strong>COD đã thu:</strong> Số tiền thu hộ đã được ký nhận nhưng chưa thanh toán tính đến thời điểm hiện
              tại (được tra cứu theo Ngày ký nhận)
            </h3>
            <br />
            <h3>
              <strong>COD chờ thanh toán：</strong>Thống kê số tiền thu hộ chưa khấu trừ các khoản phí chuẩn bị thanh
              toán cho khách hàng vào lượt đối soát tiếp theo (hiển thị theo Lịch đối soát được thiết lập)
            </h3>
            <br />
            <h3 class="text-theme-1">** Số tiền COD đang hiển thị chưa khấu trừ các khoản phí.</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useManager } from "./useManager";

export default defineComponent({
  setup() {
    const { loading, dateRange, searchTraffic, codsPayed, codsWait } = useManager();

    return { loading, dateRange, searchTraffic, codsPayed, codsWait };
  },
});
</script>
