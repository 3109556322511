
import { defineComponent } from "vue";
import { useManager } from "./useManager";

export default defineComponent({
  setup() {
    const { loading, dateRange, searchTraffic, codsPayed, codsWait } = useManager();

    return { loading, dateRange, searchTraffic, codsPayed, codsWait };
  },
});
