import { onMounted, ref } from "vue";

import Tabulator from "tabulator-tables";
import dayjs from "dayjs";

import { DATE_FORMAT } from "@/configs/constants";
import { helper as $h } from "@/utils/helper";
import { orderService } from "@/services/order";

export const useManager: any = () => {
  const loading = ref(false);
  const today = dayjs().format(DATE_FORMAT);
  const lastMonth = dayjs().subtract(1, "month").format(DATE_FORMAT);
  const dateRange = ref<string>(`${lastMonth} - ${today}`);
  const codsPayed = ref<any>({});
  const codsWait = ref<any>({});

  const getParamWaitingCod = () => {
    const today = dayjs();
    let startDate = dayjs();
    let endDate = dayjs();
    switch (today.day()) {
      case 0:
        startDate = dayjs().subtract(2, "day");
        break;
      case 1:
        startDate = dayjs().subtract(3, "day");
        endDate = dayjs().subtract(1, "day");
        break;
      case 2:
        startDate = dayjs().subtract(1, "day");
        break;
      case 3:
        startDate = dayjs().subtract(1, "day");
        endDate = dayjs().subtract(2, "day");
        break;
      case 4:
        startDate = dayjs().subtract(1, "day");
        break;
      case 5:
        startDate = dayjs().subtract(2, "day");
        endDate = dayjs().subtract(1, "day");
        break;
      case 6:
        startDate = dayjs().subtract(1, "day");
        break;
    }

    return [startDate, endDate];
  };
  const timeWaitingCod = getParamWaitingCod();

  const searchTraffic = async () => {
    const [startDate, endDate] = $h.dateRangeToDate(dateRange.value);
    const params = {
      startDate: startDate,
      endDate: endDate,
    };

    const params2: any = {
      startDate: timeWaitingCod[0].format("YYYY/MM/DD") + " 00:00:00",
      endDate: timeWaitingCod[1].format("YYYY/MM/DD") + " 23:59:59",
    };

    try {
      loading.value = true;
      const [res1, res2] = await Promise.all([orderService.getCodTraffic(params), orderService.getCodTraffic(params2)]);
      if (res1?.result?.data && res2?.result?.data) {
        codsPayed.value = res1?.result?.data;
        codsWait.value = res2?.result?.data;
      }
    } finally {
      loading.value = false;
    }
  };

  onMounted(() => {
    searchTraffic();
  });

  return {
    dateRange,
    loading,
    searchTraffic,
    codsPayed,
    codsWait
  };
};
